import { Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { defaultSectionWidth, largeText, pageSubtitle, regularText, smallText } from '../../styles/dimensions';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import content from '../../content/blog/blogArticle.json';
import MetroSans from '../../fonts/metro-sans/metroSans';
import RigrafExpanded from '../../fonts/rigraf/expanded/rigrafExpanded';
import RigrafItalic from '../../fonts/rigraf/italic/rigrafItalic';
import colors from '../../styles/colors';
import Rigraf from '../../fonts/rigraf/regular/rigraf';
import StoryTelling from '../../elements/storyTelling';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { removeWidows } from 'string-remove-widows';

deckDeckGoHighlightElement();

const components = { StoryTelling };

interface Props {
	children: any;
	location: any;
	navigate: (to: string, options: any) => void;
	pageContext: {
		frontmatter: {
			title: string;
			metaTitle?: string;
			author: string;
			category: string;
			date: string;
			src: {
				childImageSharp: any;
			};
			alt: string;
			description: string;
			showDescription: boolean;
		};
		body: any;
		timeToRead: number;
	};
	pageResources: any;
	params: any;
	path: string;
	uri: string;
}

const DefaultBlogLayout = (props: Props) => {
	const { pageContext } = props;

	const classes = useClasses();

	const dateTime = useMemo(() => {
		return new Date(pageContext.frontmatter.date).toISOString();
	}, []);

	const title = useMemo(() => {
		if (pageContext.frontmatter.title.includes('\u2011')) {
			return pageContext.frontmatter.title;
		}
		return removeWidows(pageContext.frontmatter.title).res;
	}, []);

	return (
		<Layout>
			<SEO
				title={pageContext.frontmatter.title}
				description={pageContext.frontmatter.description}
				image={pageContext.frontmatter.src.childImageSharp.gatsbyImageData.images.fallback.src}
			/>

			<Grid container id="blog-post" className={classes.grid}>
				<Grid item sm={1} md={2}></Grid>

				<Grid item xs={12} sm={10} md={8} className={classes.gridMainColumn}>
					<section>
						<span className={classes.read}>
							{pageContext.timeToRead}
							{content.read}
						</span>

						<h1 className={classes.header} dangerouslySetInnerHTML={{ __html: title }}></h1>

						<div className={classes.detailsContainer}>
							<div className={classes.detailsItem}>
								<h6 className={classes.subHeader}>{content.author}</h6>
								<p className={classes.detailsDescription}>{pageContext.frontmatter.author}</p>
							</div>
							<div className={classes.detailsItem}>
								<h6 className={classes.subHeader}>{content.date}</h6>
								<p className={classes.detailsDescription}>
									<time dateTime={dateTime}>{pageContext.frontmatter.date}</time>
								</p>
							</div>
							<div className={classes.detailsItem}>
								<h6 className={classes.subHeader}>{content.category}</h6>
								<p className={classes.detailsDescription}>{pageContext.frontmatter.category}</p>
							</div>
						</div>

						<GatsbyImage
							image={pageContext.frontmatter.src.childImageSharp.gatsbyImageData}
							alt={pageContext.frontmatter.alt}
							className={classes.mainImage}
							loading="eager"
						/>

						{pageContext.frontmatter.showDescription && (
							<p className={classes.description}>{pageContext.frontmatter.description}</p>
						)}
					</section>

					<section className={classes.mdxSection}>
						<MDXProvider components={components}>
							<MDXRenderer>{pageContext.body}</MDXRenderer>
						</MDXProvider>
					</section>
				</Grid>

				<Grid item sm={1} md={2}></Grid>
			</Grid>
		</Layout>
	);
};

export default DefaultBlogLayout;

const useClasses = makeStyles({
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: 'calc(7.625em + 2px + 5.5em)',
		paddingBottom: '5.75em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: 'calc(3.75em + 2px + 2.5em)',
			paddingBottom: '4em',
		},
	},
	gridMainColumn: {
		'@media (min-width: 960px)': {
			paddingLeft: '4em',
			paddingRight: '4em',
		},
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
		},
	},
	detailsItem: {
		'@media (max-width: 599px)': {
			marginBottom: '1em',
		},
	},
	read: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		marginBottom: '0.5em',
		color: colors.textGrey,
	},
	header: {
		fontFamily: RigrafExpanded.semiBold,
		fontWeight: 'normal',
		fontSize: pageSubtitle.fontSize,
		lineHeight: pageSubtitle.lineHeight,
		marginTop: 0,
		marginBottom: '1.3em',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			marginBottom: '0.8333333333333334em',
		},
	},
	subHeader: {
		margin: 0,
	},
	detailsDescription: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		margin: 0,
	},
	mainImage: {
		maxWidth: 'unset',
		width: 'calc(100% + 16.66666667% * 4)',
		position: 'relative',
		left: '50%',
		transform: 'translateX(-50%)',
		marginTop: '3em',
		marginBottom: '3.25em',
		'@media (max-width: 959px)': {
			width: '100%',
			marginTop: '1em',
			marginBottom: '1em',
		},
	},
	mdxSection: {
		'& img': {
			marginTop: '1.75em',
			marginBottom: '2.25em',
			'@media (max-width: 959px)': {
				marginBottom: '3em',
			},
		},
		'& svg': {
			maxWidth: '100%',
			height: 'auto',
			marginBottom: '2.25em',
			'@media (max-width: 959px)': {
				marginBottom: '3em',
			},
		},
		'& p': {
			fontFamily: MetroSans.book,
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			color: colors.textGrey,
			'@media (max-width: 599px)': {
				fontSize: smallText.fontSize,
				lineHeight: smallText.lineHeight,
			},
		},
		'& blockquote': {
			borderLeft: '1px solid #3D3D3D',
			margin: 0,
			marginTop: '2.75em',
			marginBottom: '3em',
			padding: '1em 0',
			paddingLeft: 'calc(8.33% * 2)',
			'@media (max-width: 599px)': {
				paddingLeft: '1em',
				marginTop: '2em',
				marginBottom: '2.2em',
			},
		},
		'& blockquote p': {
			fontFamily: RigrafItalic.extraLight,
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			color: colors.textBlack,
			'@media (max-width: 599px)': {
				fontSize: smallText.fontSize,
				lineHeight: smallText.lineHeight,
			},
		},
		'& blockquote p strong': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
		},
		'& h1': {
			marginTop: '1.3em',
			marginBottom: '0.4em',
		},
		'& h2': {
			marginTop: '1.3em',
			marginBottom: '0.5em',
		},
		'& h3': {
			marginTop: '1.3em',
			marginBottom: '0.5em',
		},
		'& h4': {
			marginTop: '1.3em',
			marginBottom: '0.5em',
		},
		'& h5': {
			marginTop: '1.3em',
			marginBottom: '0.5em',
		},
		'& h6': {
			marginTop: '1.3em',
			marginBottom: '0.5em',
		},
		'& iframe': {
			marginTop: '1.25em',
			marginBottom: '1.25em',
		},
		'& deckgo-highlight-code': {
			marginTop: '2em',
			marginBottom: '2.5em',
			boxShadow: 'var(--deckgo-highlight-code-carbon-box-shadow, rgba(0, 0, 0, 0.2) 0 8px 16px)',
		},
		'& ul li': {
			fontFamily: MetroSans.book,
			color: colors.textGrey,
		},
		'& ol': {
			paddingLeft: '20px',
		},
		'& ol li': {
			margin: '1em 0',
			fontFamily: MetroSans.book,
			color: colors.textGrey,
			listStyleType: 'decimal',
			fontSize: '1.25rem',
			lineHeight: '1.6em',
			'@media (max-width: 599px)': {
				fontsize: '1rem',
				lineHeight: '1.5em',
			},
		},
		'& a': {
			fontFamily: MetroSans.book,
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			color: colors.textGrey,
			textDecoration: 'underline',
			textUnderlineOffset: '2px',
			userSelect: 'text',
			'@media (max-width: 599px)': {
				fontSize: smallText.fontSize,
				lineHeight: smallText.lineHeight,
			},
		},
	},
	relatedGrid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: '3.625em',
		paddingBottom: '5.25em',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			paddingTop: 'calc(3.75em + 2px + 2.5em)',
			paddingBottom: '4em',
		},
	},
	description: {
		fontFamily: MetroSans.book,
	},
});
