import { styled } from '@material-ui/core';
import MetroSans from '../fonts/metro-sans/metroSans';
import colors from '../styles/colors';
import { regularText, smallText } from '../styles/dimensions';
import '../fonts/metro-sans/metro-sans-regular-italic/metro-sans-regular-italic.css';

const StoryTelling = styled('blockquote')({
    backgroundColor: '#F3F5F7',
    margin: 0,
    marginTop: '0.75em !important',
    marginBottom: '1.25em !important',
    padding: '1em !important',
    border: '0 !important',
    fontFamily: MetroSans.regularItalic + ' !important',
    fontSize: regularText.fontSize + ' !important',
    lineHeight: regularText.lineHeight + ' !important',
    color: colors.textGrey + ' !important',
    '@media (max-width: 599px)': {
        fontSize: smallText.fontSize + ' !important',
        lineHeight: smallText.lineHeight + ' !important',
    },
});

export default StoryTelling;
